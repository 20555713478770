import store from '@/store'



export default async (to, from, next) => {
  //console.log("middleware-check-operators")
  if (!store.getters['filters/check_filters']) {
    try {
      await store.dispatch('users/fetchUsers')
      await store.dispatch('filters/fetchOperatorsGroups')
    } catch (e) {
      //
    }
  }

  next()
}
