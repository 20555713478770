<template>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      app
      color="secondary"
      dark
      :class="{'d-none': $router.app.printMode}"
      v-if="!$router.app.printMode"
      
    >
    
      <div class="d-flex align-center">
        
        <!--<v-img
          alt="Vuetify Logo"
          class="shrink mr-2 hidden-sm-and-down"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />-->
        <router-link to="/" title="v2.10.15">
        <v-img
          to="about"
          alt="Vuetify Name"
          class="shrink mt-1 "
          contain
          min-width="100"
          src="@/assets/logo_white.svg"
          width="100"
        />
        </router-link>
      </div>

      <div class="d-flex align-center ml-4">

          <v-btn class="mx-1 d-none d-md-flex" text :to="{name: 'abonents'}">Абоненти</v-btn>
          <v-btn class="mx-1 d-flex d-md-none" icon :to="{name: 'abonents'}">
            <v-icon>mdi-account-multiple</v-icon>
          </v-btn>

          <template v-if="user.user_type == 1 || user.user_type == 2 || user.user_type == 3 || user.user_type == 5">  
            <v-btn class="mx-1 d-none d-md-flex" text :to="{name: 'job'}">Заявки</v-btn>
            <v-btn class="mx-1 d-flex d-md-none" icon :to="{name: 'job'}">
              <v-icon>mdi-chart-timeline-variant</v-icon>
            </v-btn>
          </template>

          <template v-if="user.user_type == 4 || user.user_type == 5 && $vuetify.breakpoint.mdAndUp">
            <v-btn class="mx-1 d-none d-md-flex" text :to="{name: 'pays'}">Платежі</v-btn>
            <v-btn class="mx-1 d-flex d-md-none" icon :to="{name: 'pays'}">
              <v-icon>mdi-credit-card-outline</v-icon>
            </v-btn>
          </template>
        
        <!-- Other menu -->  
            
            <v-menu open-on-hover buttom offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-if="$vuetify.breakpoint.mdAndUp" v-on="on" class="mx-1 d-none d-md-flex"  text >Додатки<v-icon right class="ml-1">mdi-chevron-down</v-icon></v-btn>
                
                <v-btn v-else v-on="on" class="mx-1 d-flex d-md-none" icon >
                  <v-badge
                    overlap
                    :content="countNotification"
                    color="green"
                    :value="!$vuetify.breakpoint.mdAndUp && user.user_type != 2 && countNotification"
                  >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-badge>
              </v-btn>
                
              </template>
              <v-list dense>
                <!--<v-list-item :to="{name: 'switch'}">
                  <v-icon class="pr-3">mdi-switch</v-icon>
                  <v-list-item-title>Комутатори</v-list-item-title> 
                </v-list-item>
                <v-divider/>-->
                <v-list-item v-if="user.user_type == 4 || user.user_type == 5 && $vuetify.breakpoint.smAndDown" :to="{name: 'pays'}">
                  <v-icon class="pr-3">mdi-credit-card-outline</v-icon>
                  <v-list-item-title>Платежі</v-list-item-title> 
                </v-list-item>
                <v-list-item v-if="user.user_type != 2" :to="{name: 'notifications'}">
                  <v-badge
                    inline
                    :content="countNotification"
                    color="green"
                    :value="countNotification"
                  ><v-icon class="pr-3">mdi-message-star-outline</v-icon>
                    <v-list-item-title>Сповіщення</v-list-item-title> 
                </v-badge>
                </v-list-item>
                <v-list-item v-if="user.user_type != 2" :to="{name: 'calls'}">
                  <v-icon class="pr-3">mdi-phone</v-icon>
                  <v-list-item-title>Дзвінки</v-list-item-title> 
                </v-list-item>
                <v-list-item v-if="user.user_type != 2 && user.operator_id == 1" :to="{name: 'messages'}">
                  <v-icon class="pr-3">mdi-message-bulleted</v-icon>
                  <v-list-item-title>Розсилки</v-list-item-title> 
                </v-list-item>
                <v-list-item v-if="user.user_type != 2"  :to="{name: 'reports'}">
                  <v-icon class="pr-3">mdi-poll</v-icon>
                  <v-list-item-title>Звіти</v-list-item-title> 
                </v-list-item>
                <v-list-item :to="{name: 'debtors'}">
                  <v-icon class="pr-3">mdi-account-credit-card</v-icon>
                  <v-list-item-title>Боржники (ТБ)</v-list-item-title> 
                </v-list-item>
                <v-divider />
                
                
                <v-list-item :to="{name: 'find_ports'}">
                  <v-icon class="pr-3">mdi-layers-search</v-icon>
                  <v-list-item-title>Пошук портів</v-list-item-title> 
                </v-list-item>
                <v-list-item :to="{name: 'switch'}">
                  <v-icon class="pr-3">mdi-dip-switch</v-icon>
                  <v-list-item-title>Комутатори</v-list-item-title> 
                </v-list-item>
                <v-divider />
                <v-list-item :to="{name: 'houses'}">
                  <v-icon class="pr-3">mdi-home</v-icon>
                  <v-list-item-title>Коментарі до будинків</v-list-item-title> 
                </v-list-item>
                
              </v-list>
            </v-menu> 
        <!-- / Other menu -->  

      </div>
      
      <v-btn v-if="$vuetify.breakpoint.mdAndUp && countNotification && user.user_type != 2" icon to="/notifications" >
        <v-badge
          overlap
          :content="countNotification"
          title="У вас є непрочитані сповіщення"
          color="green"
          :value="countNotification"
        >
          <v-icon color="accent">mdi-message-bulleted</v-icon>
        </v-badge>
      </v-btn>
      
      <v-spacer></v-spacer>

      <v-icon class="mr-2 d-none d-lg-flex">mdi-web</v-icon> 
      <v-toolbar-title class="d-none d-lg-flex text-body-1">{{ user.operator }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <template v-if="$vuetify.breakpoint.mdAndUp && user.user_type != 2">
        <TabsAbonentsMenu :user="user"/>
      </template>
      
      <StatusSocketBtn v-if="user && user.operator_id == 1" :user="user" />
      
      <span  class="accent--text" v-if="$vuetify.breakpoint.mdAndUp">{{ user.username.toUpperCase() }}</span>
      
      
      <div class="text-center">
        <v-menu open-on-hover buttom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
            icon
            color="accent"
                v-on="on"
              >
              <v-avatar v-if="user.avatar" size="36">
                <img  :src="$router.app.devUrl + '/media/' + user.avatar" alt="avatar">
              </v-avatar>  
              <v-icon v-else>mdi-account-circle</v-icon>
              </v-btn>
          </template>
          <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img v-if="user.avatar" :src="$router.app.devUrl + '/media/' + user.avatar" alt="avatar">
              <img v-else src="@/assets/mem2.png" alt="mem">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.username }}</v-list-item-title>
              <v-list-item-subtitle>{{ users_type.find(x => x.id == user.user_type).name }}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
          
        </v-list>
          <v-divider></v-divider>
          <v-list dense>
            <template v-if="user.username == 'dima' || user.username == 'ruslan'">
              <v-list-item @click="$router.app.printMode = !$router.app.printMode">
                <v-icon class="pr-3">mdi-printer-outline</v-icon>
                <v-list-item-title>Режим Друку</v-list-item-title>
              </v-list-item>
              <!--<v-list-item :to="{name: 'about'}">
                <v-icon class="pr-3">mdi-chart-timeline-variant</v-icon>
                <v-list-item-title>Звіт</v-list-item-title>
              </v-list-item>-->
              <v-list-item :to="{name: 'admin'}">
                <v-icon class="pr-3">mdi-account-cog</v-icon>
                <v-list-item-title>Розробникам</v-list-item-title>
              </v-list-item>
              <v-divider />
            </template>
            <v-list-item v-if="user.user_type != 2" :to="{name: 'operator_admin'}">
              <v-icon class="pr-3">mdi-account-cog</v-icon>
              <v-list-item-title>Адміністрування</v-list-item-title>
            </v-list-item>
              
            <template v-if="user.user_type != 2">
              <TabsAbonentsMenu :user="user" :typeList="true"/>
            </template>
            
            
            <!--<v-list-item @click="$router.app.isColorSettings = !$router.app.isColorSettings">
              <v-icon class="pr-3">mdi-invert-colors</v-icon>
              <v-list-item-title>Тема</v-list-item-title>
            </v-list-item>-->
            <v-list-item @click="$router.app.$refs.settingsNavDrawer.drawer = !$router.app.$refs.settingsNavDrawer.drawer">
              <v-icon class="pr-3">mdi-invert-colors</v-icon>
              <v-list-item-title>Тема</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              :to="item.to"
            >
              <v-icon class="pr-3">{{ item.icon }}</v-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

    </v-app-bar>
</template>

<script>
//import axios from "axios"
import { mapGetters } from "vuex" 
import StatusSocketBtn from '@/components/CallCenter/StatusSocketBtn.vue'
import TabsAbonentsMenu from '@/components/AbonentInfo/TabsAbonentsMenu.vue'


export default {
    components: {
      StatusSocketBtn,
      TabsAbonentsMenu
    },
    data: () => ({
        items: [
          //{ title: 'Профиль', to: "/user", icon: "mdi-account-circle" },
          { title: 'Налаштування', to: "/user/settings", icon: "mdi-cog" }, 
          { title: 'Вихід', to: "/logout", icon: "mdi-exit-to-app" }, 
        ],
    }),
    computed: {
        ...mapGetters({
          user: "auth/user",
          users_type: "users/users_type",
          countNotification: "notifications/countNotification"
        }),
    },
    watch:{
      //
    },
    mounted() {
      //console.log(this.$refs)
      if(this.user?.user_type != 2) this.$store.dispatch('notifications/fetchCountNotifications');
    },
    
    methods: {
      getTest(){
        //axios.get('http://192.168.5.17:8887/chat/check/ ')
        //axios.get('http://10.111.0.222:5000/home')
        /*axios({
          url: "http://10.111.0.222:5000/home",
          
        })*/
        //axios.get('http://10.111.0.222:5000/home')
        this.$router.app.$refs.systemBar.show()
      }
    }
}
    
</script>

<style>
nav a.router-link-exact-active {
  color: #ffc107 !important;
  font-weight: 700;
}
</style>