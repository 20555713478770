<template>
  <v-app class="app-page" :style="{color: $vuetify.theme.dark && $vuetify.theme.themes.dark.color || $vuetify.theme.themes.light.color, background: $route.name != 'home' ? $vuetify.theme.dark && $vuetify.theme.themes.dark.background + '!important' || $vuetify.theme.themes.light.background + '!important' : '', 'transform-origin': 'center top 0px'}">
    
    <Loading ref="loading"/>
    <!--<component v-if="layout" :is="layout"/>-->
    <SystemBar ref="systemBar" />
    <Navbar v-if="layout == 'auth'"/>
    <NavbarAuth v-else />
		<v-main >
     
			<transition name="page" mode="out-in">
				<router-view/>
			</transition>
      
		</v-main>
    <AlertsCall ref="alertsCall" />
    <Sheet ref="sheet"/>
    <Snack ref="snack"/>
    <SettingsNavDrawer ref="settingsNavDrawer"/>
    <!--<ColorsSettings v-if="isColorSettings"/>-->
    <Scroll />
    <CancelPrintModeBth />
    
  </v-app>
</template>

<script>
import AlertsCall from '@/components/CallCenter/AlertsCall.vue'
import SystemBar from '@/components/CallCenter/SystemBar.vue'


/*
const requireContext = require.context('@/layouts', false, /.*\.vue$/)
const layouts = requireContext.keys()
.map(file =>
    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
.reduce((components, [name, component]) => {
    components[name] = component.default || component
    return components
}, {})
*/
Date.prototype.daysInMonth = function() {
		return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
	};
Date.prototype.toDateInputValue = (function() {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});
Date.prototype.toDateYear = (function() {
    var local = new Date(this);
    local = new Date(this.getFullYear(), 0, 1)
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});


export default {
  el: '#app',

  components: {
      //layouts,
      //SnackCall,
      AlertsCall,
      SystemBar,
      CancelPrintModeBth: () => import('@/components/Tools/CancelPrintModeBtn.vue'),
      //ColorsSettings: () => import('@/components/Settings/ColorsSettings'),
      SettingsNavDrawer: () => import('@/components/Settings/SettingsNavDrawer'),
  },

  data: () => ({
    printMode: false,
    layout: null,
    defaultLayout: 'default',
    //devUrl: process.env.NODE_ENV !== 'production' && 'http://192.168.5.17:8888' || '',
    devUrl: process.env.NODE_ENV !== 'production' && 'http://10.110.0.39:8888' || '',
    devUrlServiceSms: process.env.NODE_ENV !== 'production' && 'http://10.110.0.48:8888' || '',
    devUrlServiceCalls: process.env.NODE_ENV !== 'production' && 'http://10.110.0.39:5000' || '',
    //devUrlServiceCalls: process.env.NODE_ENV !== 'production' && 'https://crmtv.altair.kr.ua' || '',
    //devUrlServiceSwitches: process.env.NODE_ENV !== 'production' && 'https://crmtv.altair.kr.ua' || '',
    devUrlServiceSwitches: process.env.NODE_ENV !== 'production' && 'http://10.110.0.38:8888' || '',
    //devUrlServiceSwitches: process.env.NODE_ENV !== 'production' && 'http://10.111.0.222:8888' || '',
    devUrlBillingAPI: process.env.NODE_ENV !== 'production' && 'http://10.110.0.39/api_death_day/' || '/api_death_day/',
    
    
    isColorSettings: false,
    settingTableJob: {},
    settingTableAbonent: {}
    
  }),
  mounted () {
    this.$loading = this.$refs.loading;
    this.$sheet = this.$refs.sheet;
    this.$snack = this.$refs.snack;

    this.$vuetify.theme.dark = localStorage.getItem('dark-theme') == 'true' && true || false;
    this.$vuetify.theme.themes.dark.primary = localStorage.getItem('dark-primary-color') || '#65769d';
    this.$vuetify.theme.themes.light.primary = localStorage.getItem('primary-color') || '#37474F';
    this.$vuetify.theme.themes.light.secondary = localStorage.getItem('secondary-color') || '#37474F';
    
    this.settingTableJob.itemsPerPage = localStorage.getItem("setting-table-job-itemsPerPage") 
    this.settingTableJob.itemsPerPage_mobile = localStorage.getItem("setting-table-job-itemsPerPage-mobile") 
    this.settingTableAbonent.itemsPerPage = localStorage.getItem("setting-table-abonent-itemsPerPage") 
    this.settingTableAbonent.itemsPerPage_mobile = localStorage.getItem("setting-table-abonent-itemsPerPage-mobile") 
    
  },

  methods: {
    /**
       * Set the application layout.
       *
       * @param {String} layout
       */
      setLayout (layout) {

      /*  
      if (!layout || !layouts[layout]) {
          layout = this.defaultLayout
      }
      this.layout = layouts[layout]
      */
      this.layout = layout
      },
      generateStartDate() {
        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        startDate = startDate.toISOString();
        startDate = startDate.slice(0, startDate.indexOf('T'));
        return startDate;
      },
      copyText(text){
        var el=document.createElement('input');
        try{
          el.value=text;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
        }finally{
          el.parentElement.removeChild(el);
          this.$refs.snack.show("Номер скопійовано у буфер!")
        }
      }
      


   }
};
</script>

<style lang="css">
@import "./assets/css/transition_page.css";
  
  h1, h2, h3, h4, h5, h6 {
    color: #333, /*#a9a9a9*/
  }
  .read--notification{
  color: grey
}
.app-page{
  /*margin-bottom: 30px*/
}
.custom_prefix_field_dark .v-text-field__prefix{
  color: #fdfdfd
}
.custom_prefix_field_ligth .v-text-field__prefix{
  color: #6a6a6a
}
.custom_prefix_field_ligth input[name="summ_fee"]{
    padding-left: 7px !important;
}
.custom_prefix_field_dark input[name="summ_fee"]{
    padding-left: 7px !important;
}
.custom-tooltip {
    opacity: 0.97!important;
}
.v-row-group__summary{
  background: none !important;
  
}
.v-row-group__summary td{
  border-top: 1px solid ;
}
</style>