export const state = {
    tabs_abonents: JSON.parse(sessionStorage.getItem("tabs_abonents")) || [],
    len_tabs: 7,
    activate_component: false,
  }
  
  // getters
  export const getters = {
    tabs_abonents: state => state.tabs_abonents, 
    activate_component: state => state.activate_component
  }
  
  // mutations
  export const mutations = {
    updateTabsAbonents(state, abonent) {
      let is_tab = state.tabs_abonents.find(x => x.id == abonent.id)
      if(is_tab){
        let index_tab = state.tabs_abonents.indexOf(is_tab)
        state.tabs_abonents.splice(index_tab, 1)
      } 
      state.tabs_abonents.unshift(abonent)
      if(state.tabs_abonents.length > state.len_tabs ) state.tabs_abonents.pop()
      state.activate_component = true
      sessionStorage.setItem("tabs_abonents", JSON.stringify(state.tabs_abonents))
      sessionStorage.setItem("activate_component_abonentInfo", true)
      
    },
    deleteTabAbonent(state, abonent) {
      let is_tab = state.tabs_abonents.find(x => x.id == abonent.id)
      if(is_tab){
        let index_tab = state.tabs_abonents.indexOf(is_tab)
        state.tabs_abonents.splice(index_tab, 1)
      } 
      sessionStorage.setItem("tabs_abonents", JSON.stringify(state.tabs_abonents))
    },
    deactivateConponent(state){
      state.activate_component = false
    }
  }
  
  // actions
  export const actions = {
    
  }