import store from '@/store'

/** Если данные пользовател является is_superuser - пропускаем дальше, если нет - перенаправляем на главную */

export default async (to, from, next) => {
  //console.log("middleware-check-client")
  if (store.getters['auth/admin']) {
    next()
  } else {
    next({ name: 'home' })
  }
}