import store from '@/store'

/** Якщо данні списку користувачів колл-центру не знайдені - намагаємось отримати їх з серверу */

export default async (to, from, next) => {
  if (!store.getters['users/call_centre_users']) {
    try {
      await store.dispatch('users/fetchCallCentreUsers')
    } catch (e) {
      //
    }
  }
  next()
}
