

export const state = {
    theme: JSON.parse(localStorage.getItem("theme")) || null,
  }
  
  // getters
  export const getters = {
    check_update: state => state.update !== null,
  }
  
  // mutations
  export const mutations = {
    updateTheme(state, theme) {
      state.theme = theme
      localStorage.setItem("user-data", JSON.stringify(theme))
    },
  
  }
  
  // actions
  export const actions = {
    updateTheme ({ commit }, theme) {
      commit("updateTheme", theme)
    },
  
  }