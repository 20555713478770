<template>
  <div >
    <v-btn
      v-if="$router.app.printMode"
      title="Вийти з режиму друку"
      color="grey"
      class="btn-scroll"
      fixed
      bottom
      right
      small
      dark
      fab
      @click="$router.app.printMode = false"
    >
      <v-icon dark>
        mdi-printer-off-outline
      </v-icon>
    </v-btn>
  </div>
      
  </template>
  <script>
  export default {
    data: () => ({
      //
    }),
    methods:{
      //
    }
  }
  </script>
  <style scoped>
    .btn-scroll {
      bottom: 15px !important;
    }
  </style>