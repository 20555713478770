import axios from 'axios'
import store from '@/store'
import router from '@/router'
import swal from 'sweetalert2'


// Request interceptor
axios.interceptors.request.use(request => {

  const token = store.getters['auth/token_access'] 

  //console.log(request, '\n', token)
  if (token) {
    //request.headers.common['Authorization'] = `Token ${token}` // `Bearer ${token}`
    request.headers.common['Authorization'] = `Bearer ${token}` // ` ${token}`
    //request.headers.common['Mitya'] = `Bearer ${token}` // ` ${token}`
  }
  /*if(request.url.indexOf('api_death_day') != -1){
    // Якщо запит в API білінгу
    request.headers.common['Authorization'] = 'Basic 1933cc448674b542e17115e414fb6545cf9e33a753778cf50489ea51928e3973'
  }*/

  // request.headers['X-Socket-Id'] = Echo.socketId()
  request.headers['Content-Type'] = 'application/json';

  if (process.env.NODE_ENV !== 'production') {
    //request.baseURL = 'http://192.168.5.17:8888'
    request.baseURL = 'http://10.110.0.39:8888'
  }
  return request
})

// Response interceptor
axios.interceptors.response.use(response => response, 
  async (error) => {
    // Ошибка сервера
    if (error.response.status >= 500) {
      swal.fire({
        type: 'error',
        title: 'Помилка сервера',
        text: 'На сервері виникла помилка, ми вже виправляємо її (напевно)',
        reverseButtons: true,
        confirmButtonText: 'Добре',
        cancelButtonText: 'Закрыти'
      })
    }
    // Страничка не найдена
    if (error.response.status === 404) {
      //router.push({ name: '404' })
      router.app.$sheet.show("404", '\nСторінку не знайдено!');
    }
    //    Помилка авторизації
    if(error.response.status === 401){
        if (error.response.config.url == '/api/user/token/') return
        if(error.response.config.url == '/api/user/token/refresh/') {  
          router.push({ name: 'logout' }).catch(() => { })
          return
        }
        
        try {
          const { data } = await axios({
            url:'/api/user/token/refresh/',
            method: "POST",
            headers: { "Content-type": "application/json; charset=UTF-8" },
            data: {"refresh": store.getters['auth/token_refresh']}
          }) // Оновлення тимчасового токену
          
          await store.commit("auth/saveAccessToken", { token_access: data.access })
          
          error.config.headers["Authorization"] = `Bearer ${data.access}`;
          return axios(error.config);
          
        } catch (e) {
          console.log("axios catch 401!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
          location.replace("/logout")
        }
        /*const success = await store.dispatch("auth/fetchRefreshToken")
        if (success){
          error.config.headers["Authorization"] = `Bearer ${store.getters['auth/token_access']}`;
          return axios(error.config);
        }else{
          router.push({ name: 'logout' }).catch(() => { })
        }*/
        
        
    }
    if(error.response.status === 400){
      console.log("error.response.status === 400")
      if(error.response.config.url == '/api/user/token/refresh/') {
        location.replace("/logout")
        return
      }
    }

    /*if (error.response.status === 401 && store.getters['auth/check']) {
      
      swal.fire({
        type: 'warning',
        title: 'Помилка сессії',
        text: 'У Вас закінчилась сессія, Вам необхідно повторно авторизуватися',
        reverseButtons: true,
        confirmButtonText: 'Добре',
        cancelButtonText: 'Закрити'
      }).then(() => {
        //store.commit('auth/LOGOUT')
          
        router.push({ name: 'logout' })
      })
    }*/

    return Promise.reject(error)
})