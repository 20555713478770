<template>
        
    <v-dialog v-model="dialog" persistent max-width="400">
        
        <v-card :loading="loadingSubmit">
            <v-card-title class="">
              <span class="headline ">Назва</span>
            </v-card-title>
            
            <v-card-text> 
              <v-container class="mt-6">
                  <v-form 
                  ref="form"
                  v-model="valid"
                  lazy-validation>
                    <v-row>     
                      <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                          <v-text-field 
                          dense
                          outlined
                          hide-details="auto"
                          :label="field.title"
                          v-model="form_data[field.name]"
                          :name="field.name"
                          :rules="field.rules && textRules || notRules"
                          :type="field.type"
                          :disabled="loadingSubmit"
                          required
                          >
                          </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit" color="primary lighten-1" @click="sendForm()">Змінити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    export default {
        name: 'default-modal',
        props: [],
        data: () => ({
            loadingSubmit:false,
            item: {},
            dialog: false,
            valid: true,
            form_data: {},
            fields: [
                {title: "Назва", name: "name",  rules: true,  type: "text", value: "" },
                ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            //
        },
        mounted(){
            //
        },
        methods: {
            show(){
                this.dialog = true;
            },
            validate () {
              if (this.$refs.form.validate()) {
                  this.snackbar = true;
              }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset()
                this.form_data = {}
            },
            sendForm(){
              if(this.$refs.form.validate()){
                this.loadingSubmit = true
                axios({
                    method: "post",
                    url: "",
                    data: {...this.form_data }
                })
                  .then(response => {
                  if(response){
                      this.$emit('');
                      this.$router.app.$snack.show("")
                      this.closeDialog()
                  }else{
                      this.$router.app.$snack.show("Помилка", 'deep-orange')
                  }
                  })
                  .catch(err => {
                  //console.log(err);
                      this.$router.app.$sheet.show("Помилка!", err);
                  })
                  .finally(() => ( this.loadingSubmit = false ))
                }
            },
        }
    }
</script>