import axios from 'axios'

export const state = {
    status: JSON.parse(sessionStorage.getItem("job_status")) || null,
    service: JSON.parse(sessionStorage.getItem("job_service")) || null,
    type: JSON.parse(sessionStorage.getItem("job_type")) || null,
    comment_type: JSON.parse(sessionStorage.getItem("job_comment_type")) || null,
    comment_type_house: JSON.parse(sessionStorage.getItem("job_comment_type_house")) || null,
  }
  
  // getters
  export const getters = {
    status: state => state.status,
    service: state => state.service,
    type: state => state.type,
    comment_type: state => state.comment_type,
    comment_type_house: state => state.comment_type_house,
    
    check_status: state => state.status !== null,
    check_service: state => state.service !== null,
    check_type: state => state.type !== null,
    check_comment_type: state => state.comment_type !== null,
    check_comment_type_house: state => state.comment_type_house !== null,

    check_job_types: state => state.status !== null && state.type !== null && state.service !== null && state.comment_type !== null && state.comment_type_house !== null 
  }
  
  // mutations
  export const mutations = {
    updateStatus(state, { status }) {
      state.status = status
      sessionStorage.setItem("job_status", JSON.stringify(status))
    },
    updateService(state, { service }) {
      state.service = service
      sessionStorage.setItem("job_service", JSON.stringify(service))
    },
    updateType(state, { type }) {
      state.type = type
      sessionStorage.setItem("job_type", JSON.stringify(type))
    },
    updateCommentType(state, { comment_type }) {
      state.comment_type = comment_type
      sessionStorage.setItem("job_comment_type", JSON.stringify(comment_type))
    },
    updateCommentTypeHouse(state, { comment_type_house }) {
      state.comment_type_house = comment_type_house
      sessionStorage.setItem("job_comment_type_house", JSON.stringify(comment_type_house))
    },
    deleteJobTypes(state){
      state.status = null
      state.service = null
      state.type = null
      state.comment_type = null
      state.comment_type_house = null
    }
  
  }
  
  // actions
  export const actions = {
    async fetchStatus ({ commit }) {
      try {
        const { data } = await axios.get('/api/service/list_order_status/') 
        //console.log(data);
        commit("updateStatus", { status: data })
      } catch (e) {
        //console.log("FETCH_STATUS_FAILURE")
      }
    },
    async fetchService ({ commit }) {
      try {
        const { data } = await axios.get('/api/service/list_order_service/') 
        //console.log(data);
        commit("updateService", { service: data })
      } catch (e) {
        //console.log("FETCH_SERVICE_FAILURE")
      }
    },
    async fetchType ({ commit }) {
      try {
        const { data } = await axios.get('/api/service/list_order_type/') 
        //console.log(data);
        commit("updateType", { type: data })
      } catch (e) {
        //console.log("FETCH_TYPE_FAILURE")
      }
    },
    async fetchCommentType ({ commit }) {
      try {
        const { data } = await axios.get('/api/service/list_comment_subj/') 
        //console.log(data);
        commit("updateCommentType", { comment_type: data })
      } catch (e) {
        //console.log("FETCH_TYPE_FAILURE")
      }
    },
    async fetchCommentTypeHouse ({ commit }) {
      try {
        const { data } = await axios.get('/api/service/list_house_subj/') 
        //console.log(data);
        commit("updateCommentTypeHouse", { comment_type_house: data })
      } catch (e) {
        //
      }
    },
    async deleteJobTypes ({ commit }) {
      commit("deleteJobTypes")
    },
    
  }