

export const state = {
    update: false,
  }
  
  // getters
  export const getters = {
    check_update: state => state.update !== false,
  }
  
  // mutations
  export const mutations = {
    updateTable(state, status) {
      state.update = status
    },
  
  }
  
  // actions
  export const actions = {
    updateTable ({ commit }, status) {
      commit("updateTable", status)
    },
  
  }