import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      dark: false, 
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: '#37474F',//colors.deepPurple, // '#42b983' #39a272
          secondary: '#37474F', // #FFCDD2 
          accent: colors.amber, // #3F51B5
          error: colors.deepOrange,
          success: colors.green,
          //warning: colors.orange,
          info: colors.blue,
          background: '#f3f3f3', // Not automatically applied '#f8f9fa' '#f1f1f1'
          color: "#333",
        },
        dark: {
          primary: '#658a9d', // '#1e262a',
          secondary: '#2f3132', // #FFCDD2
          accent: colors.amber.darken1,
          error: colors.deepOrange.darken1,
          success: colors.green.darken1,
          //warning: colors.orange.darken1,
          info: colors.blue.darken1,
          background: '#383838',//'#242424',
          color: "#eee",
          background2: '#363636'
        },
      },
      },
      icons: {
        iconfont: 'md',
      },
});
/*
{
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
}
*/