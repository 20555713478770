

export const state = {
    total: [],
  }
  
  // getters
  export const getters = {
    total: state => state.total,
  }
  
  // mutations
  export const mutations = {
    updateTotal(state, total) {
      state.total = total
    },
  }
  
  // actions
  export const actions = {
    saveTotal ({ commit }, total) {
      commit("updateTotal", total)
    },
  
  }