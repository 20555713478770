<template>
  
  <v-dialog
      v-model="dialog"
      max-width="350"
      @keydown.esc="cancel"
    >
    <v-card>
      <v-card-title class="text-h5 text-center">
        {{ title }}
      </v-card-title>

      <v-card-text class="pt-3 text-center">
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="deep-orange"
          text
          @click.native="cancel"
        >
          Ні
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary lighten-1"
          text
          @click.native="agree"
        >
          Так
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
</template>


<script>
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
    }),
    methods: {
      open(title, message) {
        this.dialog = true
        this.title = title
        this.message = message
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve(true)
        this.dialog = false
      },
      cancel() {
        this.resolve(false)
        this.dialog = false
      }
    }
  }
</script>