import axios from 'axios'

export const state = {
    users: JSON.parse(sessionStorage.getItem("users")) || null,
    users_full: JSON.parse(sessionStorage.getItem("users_full")) || [],
    call_centre_users: JSON.parse(sessionStorage.getItem("call_centre_users")) || null,
    users_type: [ 
        { name: "Колл-Центр", id: 1 }, 
        { name: "Монтажник", id: 2 },
        { name: "Оператор", id: 3 },
        { name: "Бухгалтер", id: 4 },
        { name: "Адміністратор", id: 5 },
    ]
    
    }
    
    // getters
    export const getters = {
      users_type: state => state.users_type,
      users: state => state.users,
      users_full: state => state.users_full,
      call_centre_users: state => state.call_centre_users
    }
    
    // mutations
    export const mutations = {
      updateUsers(state, { users }) {
        state.users = users
        sessionStorage.setItem("users", JSON.stringify(users))
      },
      updateUsersFull(state, { users }) {
        state.users_full = users
        sessionStorage.setItem("users_full", JSON.stringify(users))
      },
      updateCallCentreUsers(state, { users }) {
        state.call_centre_users = users
        sessionStorage.setItem("call_centre_users", JSON.stringify(users))
      },
      deleteUsers(state){
        state.users = null
        sessionStorage.setItem("users", JSON.stringify(null))
        state.call_centre_users = null
        sessionStorage.setItem("call_centre_users", JSON.stringify(null))
      },
    }
    
    // actions
    export const actions = {
      async fetchUsers({ commit }){
        try {
          const { data } = await axios.get('/api/user/get_users/');
          commit("updateUsers", { users: data })
        } catch (error) {
          //console.log("FETCH_USERS_FAILURE")
        }    
      },
      async fetchUsersFull({ commit }){
        try {
          const { data } = await axios.get('/api/user/get_all_users/');
          commit("updateUsersFull", { users: data })
        } catch (error) {
          //console.log("FETCH_USERS_FAILURE")
        }    
      },
      async fetchCallCentreUsers({ commit }){
        try {
          const { data } = await axios.get('/api/user/list_call_centre/');
          commit("updateCallCentreUsers", { users: data })
        } catch (error) {
          //console.log("FETCH_USERS_FAILURE")
        }    
      },
      async deleteUsers ({ commit }) {
        commit("deleteUsers")
      },

    }