import store from '@/store'

/** Если данные типов заявок не найдены  - пытаемся запросить данные на сервере */

export default async (to, from, next) => {
  if (!store.getters['job_types/check_job_types']) {
    try {
      await store.dispatch('job_types/fetchStatus')
      await store.dispatch('job_types/fetchService')
      await store.dispatch('job_types/fetchType')
      await store.dispatch('job_types/fetchCommentType')
      await store.dispatch('job_types/fetchCommentTypeHouse')
    } catch (e) {
      //
    }
  }

  next()
}
