import axios from 'axios'

export const state = {
    count: 0,
  }
  
  // getters
  export const getters = {
    countNotification: state => state.count,
  }
  
  // mutations
  export const mutations = {
    updateCount(state, count) {
      state.count = count
    },
  
  }
  
  // actions
  export const actions = {
    fetchCountNotifications ({ commit }) {
      axios.get(`/api/reminder/get_count_reminders/`)
      .then( response =>{
        commit("updateCount", response.data.total || 0)
      })
      
      
    },
  
  }