import store from '@/store'

/** Если данные списока пользователей не найдены  - пытаемся запросить данные на сервере */

export default async (to, from, next) => {
  if (!store.getters['users/users']) {
    try {
      await store.dispatch('users/fetchUsers')
    } catch (e) {
      //
    }
  }

  next()
}
