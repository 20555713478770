import axios from 'axios'
import Cookies from 'js-cookie'

import * as types from '../mutation-types'

// state
export const state = {
  user: JSON.parse(sessionStorage.getItem("user-data")) || null,
  //token: Cookies.get('token'),
  token_access: null,
  token_refresh: Cookies.get('token_refresh'),
  token_access_exp: null,
  token_refresh_exp: null,
}

// getters
export const getters = { 
  user: state => state.user,
  token_access: state => state.token_access,
  token_refresh: state => state.token_refresh,
  check_user: state => state.user !== null,
  token_access_exp: state => state.token_access_exp,
  isTokenAccessActive: state => state.token_access !== null && state.token_access_exp > Date.now(),
  isTokenRefreshActive: state => state.token_refresh_exp,

  call_center: state => state.user !== null && state.user.user_type == 1 && true || false,
  monter: state => state.user !== null && state.user.user_type == 2 && true || false, 
  operator: state => state.user !== null && state.user.user_type == 3 && true || false, 
  buhgalter: state => state.user !== null && state.user.user_type == 4 && true || false, 
  admin: state => state.user !== null && state.user.user_type == 5 && true || false,
  isAdminOrBuh: state => state.user !== null && (state.user.user_type == 5 || state.user.user_type == 4) && true || false,

   
}

// mutations
export const mutations = {
  /*[types.SAVE_TOKEN] (state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : 365 })
  },*/
  saveAccessRefreshToken(state,{token_access, token_refresh, remember_refresh}){

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 2)
    tomorrow.setHours(3,0,0,0)

    state.token_access = token_access
    state.token_refresh = token_refresh
    Cookies.set('token_refresh', token_refresh, { expires: remember_refresh ? remember_refresh  : new Date (tomorrow) })

    const date = new Date()
    const dateCopy = new Date(date);
    dateCopy.setMinutes(date.getMinutes() + 59);
    state.token_access_exp = dateCopy

    
  },
  saveAccessToken(state, { token_access }) {
    state.token_access = token_access

    const date = new Date()
    const dateCopy = new Date(date);
    dateCopy.setMinutes(date.getMinutes() + 59);
    state.token_access_exp = dateCopy
  },

  [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user
    sessionStorage.setItem("user-data", JSON.stringify(user))
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.token_access = null
    state.token_refresh = null
    Cookies.remove('token_refresh')
  },

  [types.LOGOUT] (state) {
    state.user = null
    state.token_access = null
    state.token_refresh = null
    state.token_access_exp = null
    //sessionStorage.setItem("user-data", null)
    sessionStorage.clear()
    //console.log("[types.LOGOUT] ")
    Cookies.remove('token_refresh')
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user
    sessionStorage.setItem("user-data", JSON.stringify(user))
  }
}

// actions
export const actions = {
  saveToken ({ commit }, payload) {
    commit("saveAccessRefreshToken", payload)
  },
  
  async fetchRefreshToken ({ state, commit }) {  
    try {
      const { data } = await axios({
        url:'/api/user/token/refresh/',
        method: "POST",
        headers: { "Content-type": "application/json; charset=UTF-8" },
        data: {"refresh": state.token_refresh}
      }) // Оновлення тимчасового токену
      commit("saveAccessToken", { token_access: data.access })
      return true
      
    } catch (e) {
      commit(types.LOGOUT)
      console.log("store catch !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      //location.replace("/logout")
      return false
    }
  },
  async fetchUser ({ commit }) {
    try {
      const { data } = await axios.get('/api/user/get_current_user/') // Получение объекта user с сервера
      //console.log(data);
      
      commit(types.FETCH_USER_SUCCESS, { user: data })
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
    }
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  async logout ({ commit }) {
    try {
      //await axios.post('/api/v1/auth/logout/')
    } catch (e) {
      // 
    }

    commit(types.LOGOUT)
  },

}
