import Vue from 'vue'

Vue.filter('formatDate', function (date) {
    let list_date = date.split('-')
    return `${list_date[2]}-${list_date[1]}-${list_date[0][2] + list_date[0][3]}`
})

Vue.filter('formatMac', function (raw_mac) {
    let mac = raw_mac.replace(/\.+/g, '').toUpperCase()
    let temp_mac = ''
    let count_letter = 0
    for(let letter in mac){
        if(count_letter == 2){
            temp_mac += '-'
            count_letter = 0
        }
        count_letter += 1
        temp_mac += mac[letter]
    }
    return temp_mac
})

Vue.filter('formatSum', function (sum) {

    if(!sum) return

    // Перетворюємо суму у рядок і визначаємо позицію десяткової крапки
    let sumStr = sum.toString();
    let decimalIndex = sumStr.indexOf('.');

    // Якщо десяткова крапка відсутня, додаємо її в кінець
    if (decimalIndex === -1) {
        sumStr += ".00";
        decimalIndex = sumStr.length - 3;
    }

    // Розділяємо рядок на дві частини: до десяткової крапки та після
    let integerPart = sumStr.slice(0, decimalIndex);
    let decimalPart = sumStr.slice(decimalIndex);

    // Додаємо пробіл після кожного блоку з трьох цифр у цілій частині числа
    let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // З'єднуємо обидві частини та повертаємо результат
    let formattedSum = formattedIntegerPart + decimalPart;
    return formattedSum;
})