import store from '@/store'

/** Удаляем данные списока пользователей */

export default async (to, from, next) => {
  
    try {
      await store.dispatch('filters/deleteFilters')
    } catch (e) {
      //
    }
  

  next()
}
