import axios from 'axios'

export const state = {
        tags: JSON.parse(sessionStorage.getItem("tags")) || [],
        order_tags: JSON.parse(sessionStorage.getItem("order_tags")) || [],
        
    }
    
    // getters
    export const getters = {
      tags: state => state.tags,
      order_tags: state => state.order_tags,
    }
    
    // mutations
    export const mutations = {
      updateTags(state, { tags }) {
        state.tags = tags
        sessionStorage.setItem("tags", JSON.stringify(tags))
      },
      deleteTags(state){
        state.tags = null
        sessionStorage.setItem("tags", JSON.stringify([]))
      },
      updateOrderTags(state, { order_tags }) {
        state.order_tags = order_tags
        sessionStorage.setItem("order_tags", JSON.stringify(order_tags))
      },
      deleteOrderTags(state){
        state.order_tags = null
        sessionStorage.setItem("order_tags", JSON.stringify([]))
      },
    }
    
    // actions
    export const actions = {
      async fetchTags({ commit }){
        try {
          const { data } = await axios.get('/api/cabletv/user_tag/');
          commit("updateTags", { tags: data })
        } catch (error) {
          //console.log("FETCH_USERS_FAILURE")
        }    
      },
      async deleteTags ({ commit }) {
        commit("deleteTags")
      },
      async fetchOrderTags({ commit }){
        try {
          const { data } = await axios.get('/api/service/order_tag/');
          commit("updateOrderTags", { order_tags: data })
        } catch (error) {
          //console.log("FETCH_USERS_FAILURE")
        }    
      },
      async deleteOrderTags ({ commit }) {
        commit("deleteOrderTags")
      },

    }