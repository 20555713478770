export const state = {
    phoneBuffer: JSON.parse(localStorage.getItem("boffer-phone")) || '',
  }
  
  // getters
  export const getters = {
    phoneBuffer: state => state.phoneBuffer,
  }
  
  // mutations
  export const mutations = {
    updateBufferPhone(state, phone) {
      state.phoneBuffer = phone
      localStorage.setItem("boffer-phone", JSON.stringify(phone))
    },
  }
  
  // actions
  export const actions = {
    //
  }