<template>
	<div class="block-call-static-alets" ref="div" @mouseover="mouseoverBlock = true" @mouseleave="mouseoverBlock = false">
		<template v-if="hideListCalls">
			<v-btn
				color="green"
				class="btn-call-hide"
				fixed
				bottom
				left
				small
				dark
				fab
				@click="hideListCalls = !hideListCalls"
			>
				<v-icon dark>
				mdi-phone
				</v-icon>
			</v-btn>
		</template>
		<template v-else>
		<div class="d-flex justify-space-between mb-2">
			
			<template v-if="mouseoverBlock">
				<v-chip small color="grey" class="ml-2 white--text" title="Звернути" v-if="calls.length > 0" @click="hideListCalls = !hideListCalls"><v-icon small>mdi-eye-off-outline</v-icon></v-chip>
				<v-chip small color="grey" class="ml-2 white--text" v-if="calls.length > 2" @click="deleteAllCalls"><v-icon left small>mdi-close</v-icon>Закрити всі</v-chip>
				<v-chip small color="primary lighten-1" class="ml-2" v-if="calls.length > 0" @click="scrollBlockAlert">{{ calls.length }} {{ 5 > calls.length  &&  'Сповіщення' || 'Сповіщень'}}<v-icon v-if="offsetTop != 0" right>mdi-chevron-down</v-icon></v-chip>
			</template>
		</div>
		<div class="block-call-alets" id="block-call-alets" v-scroll:#block-call-alets="onScroll">
			

		<TransitionGroup tag="ul" name="fade" class="container">
		<v-alert 
			
			v-for="call in calls" :key="call.count"
			:color="call.color"
			dark
			elevation="0"
			class="alert-call pa-3"
		>
		<v-btn icon small absolute right @click="deleteCall(call)"><v-icon color="grey">mdi-close</v-icon></v-btn>
		<h5 v-if="call.recive" class="white--text">Прийняв: {{ getStateOwnerName(call.recive) }}</h5> 
		<h5 v-else class="green--text">{{ call.title }}</h5>
		<h3 class="mt-1 mb-2 white--text" style="max-width: 280px; min-width: 280px;">{{ call.phone }} </h3>
		<template v-if="call.users_data && call.users_data.length != 0">
				<div v-for="(user, index) of call.users_data" :key="index">
						<v-divider v-if="call.users_data.length > 1" class="mt-1" />
						
						<router-link :to="{ name: 'abonent_info', params: { id: user.id }}" style="text-decoration: none;"><h5 style="max-width: 280px; min-width: 280px; text-decoration: none;" class="mt-1 mb-1 accent--text">{{ user.address }} <span style="color: #9d9d9d; white-space: nowrap;" v-if="user.operator">({{ user.operator }})</span> </h5></router-link>
						<h5 class="mt-2 mb-1 white--text">{{ user.full_name }} </h5>
						
				</div>
		</template>
		<span v-else class="grey--text">Немає данних про абонента</span></v-alert>
		</TransitionGroup>
		</div>
		</template>
	</div>
</template>

<script>
import axios from "axios"
import {mapGetters} from 'vuex'
	export default {
		data: () => ({
			calls: [],
			countCalls: 0,
			offsetTop: 0,
			hideListCalls: false,
			mouseoverBlock: false
		}),
		computed: {
			...mapGetters({
				call_centre_users: "users/call_centre_users",
			}),
		},
		mounted(){
			this.countCalls = 0
		},
		methods:{
			getUserForPhone(phone){
				let is_phoneInList = this.calls.find(x => x.phone == phone)
				if(!is_phoneInList){
					this.countCalls += 1
					axios.get(`/api/cabletv/find_phones/?phone=${phone}`)
					.then((req) => {
						this.calls.push({title: "Вхідний виклик...", title_color: 'green', phone: phone, users_data: req.data.results, count: this.countCalls, color: 'secondary'})
						//setTimeout(() => {this.calls[this.calls.length - 1].alert = true},100)
					})
				}
			},
			setResiveCall(phone, call_centre){
				let is_phoneInList = this.calls.find(x => x.phone == phone)
				if(is_phoneInList){
					const i = this.calls.indexOf(is_phoneInList)
					this.calls[i].title = "Йде розмова..."
					this.calls[i].recive = call_centre
					this.calls[i].color = 'secondary lighten-3'
					
				}
			},
			deleteReciveCall(phone){
				let is_phoneInList = this.calls.find(x => x.phone == phone)
				if(is_phoneInList){
					this.deleteCall(is_phoneInList)
				}
			},
			onScroll (e) {
				//console.log(e.target.scrollTop)
				this.offsetTop = e.target.scrollTop || 0
			},
			scrollBlockAlert(){
				document.getElementById("block-call-alets").scrollTo({
					top: 0,
					behavior: 'smooth'
				})
			},
			deleteCall(item){
				/*console.log("deleteCall")
				
				this.calls.splice(index_call, 1)*/

				const i = this.calls.indexOf(item)
				
				if (i > -1) {
					this.calls.splice(i, 1)
					//this.calls[i].alert = false
					//setTimeout(() => {},500)
				}
			},
			deleteAllCalls(){
				this.calls = []
				this.countCalls = 0
			},
			getStateOwnerName(state_owner){
				let user = this.call_centre_users && this.call_centre_users.find(x => x.call_centre == state_owner)
				if(user){
					//return user.on_duty && 'Черговий' || user.name
					return user.on_duty && 'Черговий' || user.name
				}else {
					return state_owner
				}
			},
		}
		
	}
</script>
<style scoped>
.block-call-static-alets{
	position:fixed;
	z-index: 5;
    bottom: 30px;
    margin-left: 15px;
}
.block-call-alets {
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	max-height: 40vh;

	display: flex;
	flex-direction: column-reverse;
}
.block-call-alets::-webkit-scrollbar {
      width: 0;
      height: 0;
}

.container {
  position: relative;
  padding: 0;
}
.alert-call:last-child{
	margin-bottom: 0px;
}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}

.blur-border::before{
  content: '';
  display: inline-block;
  position: absolute;
  top:0; /* растягиваем под размер блока */
  left:0;
  right:0;
  bottom:0; 
  margin: -8px; /* делаем отступ наружу*/
  border: 3px solid var(--border-color);
  filter: blur(1px);
}
.btn-call-hide {
    bottom: 60px !important;
  }
</style>