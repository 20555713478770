<template>
    <v-autocomplete
        v-model="searchId"
        :items="resultSearchStreet"
        :loading="isLoadingStreets"
        :search-input.sync="searchStreet"
        :dense="denseForm || false"
        :outlined="outlinedForm || false"
        :hide-no-data="!searchStreet || searchStreet.length < 2 || searchStreet.length > 1 && resultSearchStreet.length != 0"
        :rules="rules"
        hide-selected
        hide-details="auto"
        item-text="street_view"
        item-value="id"
        label="Вулиця"
        placeholder="Введіть частину назви"
        :prepend-icon="iconForm || ''"
        clearable
        no-data-text="Не знайдено"
        no-filter
    >
    </v-autocomplete>
</template>

<script>
import axios from "axios"
export default {
  props: ["outlinedForm", "iconForm", "denseForm", "clearSearchStreet", "rules"],
  data: () => ({
      searchStreet: '',
      isLoadingStreets: false,
      resultSearchStreet: [],
      searchId: null
  }),
  created: function () {
    this.debounceSearchLoading = require('lodash').debounce(this.getStreet, 500);
  },
  watch: {
    searchId(){
      let searchData = this.resultSearchStreet.find(x => x.id == this.searchId) // Шукаємо вулицю в об'єкті щоб витягнути текстову назву для другого аргументу нижче
      this.$emit("search-new-id", this.searchId, searchData && searchData.street || '')
    },
    clearSearchStreet(){
      console.log("clearSearchStreet")
      this.resultSearchStreet = []
      this.searchId = null
      this.searchStreet = ''
    },
    searchStreet(val){
      if (!val || val.length < 2) {
        this.resultSearchStreet = []
        this.searchId = null
        //console.log("search no")
        return
      }
      if(!this.searchId){
        this.debounceSearchLoading(val)
      }
      
    }
  },
  methods: {
    getStreet(val){
      this.isLoadingStreets = true
      axios.get('/api/cabletv/list_street/', {params: {search: val}})
      .then(res => {
        this.resultSearchStreet = res.data.results
      })
      .catch(() => {
        //console.log(err)
      })
      .finally(() => (this.isLoadingStreets = false))
    }
  } 
}
</script>