<template>
	<div>
		<v-menu open-on-hover buttom offset-y v-if="$vuetify.breakpoint.mdAndUp && user.user_type != 2" >
            <template v-slot:activator="{ on }">
				<v-btn 
					v-on="on"
					class="mr-4" 
					icon
					@click="disconnectSocket()"
				>
					<v-icon v-if="!socketStatus" color="grey">mdi-cellphone-off</v-icon>
					<v-icon v-else 
								:color="callCenterStatus[socketStatus].color"
							>
								{{ callCenterStatus[socketStatus].icon }}
						</v-icon>
				</v-btn>
			</template>
              <v-list dense>
                <v-subheader v-if="!socketStatus">З'єднання неактивне</v-subheader>
                <v-subheader v-else>{{ callCenterStatus[socketStatus].info }}</v-subheader>
                

				<v-list-item v-if="socketStatus == 'connected'"  @click="disconnectSocket()">
                  <v-icon class="pr-3">mdi-cellphone-off</v-icon>
                  <v-list-item-title>Зупинити</v-list-item-title> 
                </v-list-item>
				<v-list-item v-if="socketStatus == 'pause'"   @click="connectSocket()">
                  <v-icon class="pr-3">mdi-cellphone-check</v-icon>
                  <v-list-item-title>Підключитись</v-list-item-title> 
                </v-list-item>
				<!--<v-list-item  @click="$router.app.$refs.systemBar.show('+380676377043')">
                  <v-icon class="pr-3">mdi-phone-in-talk</v-icon>
                  <v-list-item-title>Підняти слухавку</v-list-item-title> 
                </v-list-item>-->
				<v-divider class=""></v-divider>
				<v-list-item :to="{name: 'calls'}">
                  <v-icon class="pr-3">mdi-phone</v-icon>
                  <v-list-item-title>Розділ Дзвінків</v-list-item-title> 
                </v-list-item>
                
                
              </v-list>
            </v-menu> 
		
		
	</div>
</template>

<script>
import { mapGetters } from "vuex" 

export default {
	name: 'status-socket-btn',
	props: ["user"],
	components: {
		//
	},
	data: () => ({
			watchSocket: null,
			callCenterStatus: {
				connected: {icon: 'mdi-cellphone-check', color: 'green', info: "З'єднання активне"},
				reconnecting: {icon: 'mdi-cellphone-settings', color: 'grey', info: "Підключення..."},
				disconnected: {icon: 'mdi-cellphone-remove', color: 'red', info: "З'єднання розірвано"},
				pause: {icon: 'mdi-cellphone-off', color: 'grey', info: "Призупинено"},
				in_call: {icon: 'mdi-phone-incoming', color: 'green', info: "Вхідний виклик"},
				in_talk: {icon: 'mdi-phone-in-talk', color: 'green', info: "Йде дзвінок"},
				not_access: {icon: 'mdi-cellphone-information', color: 'deep-orange', info: "У вам немає прав доступу"}
				
			}
	}),
		computed: {
			...mapGetters({
				socketStatus: "socket/status",
				accessToken: "auth/token_access"
			}),
	},
		watch:{
		socketStatus(){
		//
		}
	},
		mounted() {
			//this.$socket.io.opts.extraHeaders = {'Authorization': `Bearer ${this.accessToken}`}
			//this.$socket.connect()
			//console.log("this.$socket: ", this.$socket)

			this.connectSocket()
	},
	beforeDestroy() {
		this.$socket.disconnect()
	},
	sockets: {
		/*connect: function () {
			console.log('socket connected')
			//console.log("this.$socket: ", this.$socket)
		},*/
		error: async function (error){
			console.log(error)
			if(error.error_code == 1){ // Сплив час дії токену.
				await this.$store.dispatch('auth/fetchRefreshToken') // Намагаємось поновити токен
				this.connectSocket()
			}
			else if(error.error_code == 2){ // Надано недійсний Токен
				await this.$store.dispatch('auth/fetchRefreshToken')
				this.connectSocket()
			}
			else if(error.error_code == 3){ // Токен не надано
				this.$socket.disconnect() 
				this.$store.commit("socket/updateStatus", "disconnected")
				this.$router.app.$sheet.show("Помилка!", error.message);
			}
			else if(error.error_code == 4){ // У вам немає прав доступу
				this.$store.commit("socket/updateStatus", "not_access")
			}
		},
		make_call: function (data) {
			console.log(data)
			this.$router.app.$refs.systemBar.show(data.phone_number, 'make-call')
		},
		call: function (data) {
			console.log("New call")
			console.log(data)
			//this.$refs.snackCall.messages.push({title: "Вхідний виклик...", phone: data, users_data: []})
			this.$router.app.$refs.alertsCall.getUserForPhone(data)
		},
		recive: function (data){
			console.log("New recive")
			console.log(data)
			if(this.user.call_centre == data.call_centre){
				//this.$refs.snackCall.messages.push({title: "Прийнятий виклик:", phone: data.phone, user_data: {}})
				this.$router.app.$refs.systemBar.show(data.phone, 'call')
				this.$router.app.$refs.alertsCall.deleteReciveCall(data.phone)
			}else{
				this.$router.app.$refs.alertsCall.setResiveCall(data.phone, data.call_centre)
			}
		},
		finished: function (data){
			//data.call_centre
			//data.phone
			if(this.user.call_centre == data.call_centre){//this.$refs.snackCall.messages.push({title: "Прийнятий виклик:", phone: data.phone, user_data: {}})
				this.$router.app.$refs.systemBar.closeBar()
			}
			this.$router.app.$refs.alertsCall.deleteReciveCall(data.phone)
		}
		/*message: function (data){
			console.log("New message socket")
			console.log(data)
		}*/
	},
	methods: {
		connectSocket(){
			if(this.accessToken) {
				this.$socket.io.opts.extraHeaders = {'Authorization': `Bearer ${this.accessToken}`}
			}
			this.$socket.connect()
			this.$store.commit('socket/updateStatus', 'reconnecting')
			//this.$refs.snackCall.messages.push({address:'пл. Петлякова 1/66', operator:'ТОВ Кривбастелеком', phone: '+380965238547'})
		},
		disconnectSocket(){
			this.$socket.disconnect() 
			this.$store.commit('socket/updateStatus', 'pause')
			//this.$refs.snackCall.messages.push({address:'пр. 200-річчя Кривого Рогу 2/5', operator:'ПП Сателит', phone: '+380688664061', fio: 'МАСЛЯЕВА Т.В.'})
		}
	}
}
</script>
