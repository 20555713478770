import store from '@/store'

/** Если данные фильтров не найдены  - пытаемся запросить данные на сервере */

export default async (to, from, next) => {
  //console.log("middleware-check-operators")
  if (!store.getters['filters/check_filters']) {
    try {
      await store.dispatch('filters/fetchOperators')
      await store.dispatch('filters/fetchPaysType')
      await store.dispatch('filters/fetchRates')
      await store.dispatch('filters/fetchOperatorsGroups')
      await store.dispatch('filters/fetchDistricts')
      await store.dispatch('tags/fetchTags')
      await store.dispatch('tags/fetchOrderTags')
      //store.dispatch('vlans/fetchVlans')
    } catch (e) {
      //
    }
  }

  next()
}
