import store from '@/store'

/** Если данные пользователя не найдены но найден токен авторизации (был ранее авторизирован) - пытаемся запросить данные о пользователе на сервере */

export default async (to, from, next) => {
  //console.log("middleware-check-user")
  if (!store.getters['auth/check_user'] && (store.getters['auth/token_access'] || store.getters['auth/token_refresh'])) {
    try {
      await store.dispatch('auth/fetchUser')  
    } catch (e) {
      //
    }
  }

  next()
}
