import store from '@/store'

/** Удаляем данные списока пользователей */

export default async (to, from, next) => {
  if (store.getters['users/users']) {
    try {
      await store.dispatch('users/deleteUsers')
    } catch (e) {
      //
    }
  }

  next()
}
