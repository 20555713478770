<template>
    <div>
    <v-menu :open-on-hover="!typeList" buttom offset-y v-if="typeList || tabs_abonents.length > 0" >
        <template v-slot:activator="{ on }">
            <v-btn 
            v-if="!typeList"
                v-on="on"
                class="mr-0" 
                icon
                @click="1+1"
            >
                <v-icon :color="$vuetify.theme.dark ? 'primary' : ''">mdi-history</v-icon>
            </v-btn>
            <template v-else>
                <v-list-item v-on="on">
                    <v-icon class="pr-3">mdi-history</v-icon>
                    <v-list-item-title>Історія</v-list-item-title>
                </v-list-item>
            </template>
        </template>
            <v-list dense >
                <v-subheader>Історія перегляду абонентів:</v-subheader>
                <v-list-item v-for="(item, index) in tabs_abonents" :key="index" title="Відкрити абонента" 
                    @click="openAbonentInfoModal(item.id)"
                >
                <v-icon small color="primary" class="pr-4">mdi-tab</v-icon>
                
                    <v-list-item-title>{{ item.street}} {{ item.house }}{{ item.letter }} {{ item.flat }}
                    
                    <v-list-item-subtitle class="pt-1 text-caption">{{ item.date_view }}</v-list-item-subtitle>
                    </v-list-item-title> 
                    
                
                <v-list-item-action>
                    <v-btn small icon @click.stop="deleteTab(item)">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="tabs_abonents.length == 0">
                    <v-list-item-subtitle class="pt-1 text-caption">Пусто</v-list-item-subtitle>
                </v-list-item>
            </v-list>
    </v-menu> 
    <keep-alive>
      <AbonentInfoModal ref="AbonentInfoModal" backTo=""/>
    </keep-alive>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    props: ['user', 'typeList'],
    components: {
        AbonentInfoModal: () => import('@/components/Job/AbonentInfoModal.vue'),
    },
    computed: {
      ...mapGetters({
        tabs_abonents: 'tabs_abonents/tabs_abonents',
        activate_component: 'tabs_abonents/activate_component'
      }),
    },
    methods:{
        openAbonentInfoModal(paramsUrlId){
            if(!this.activate_component){
                this.$router.push({ name: 'abonent_info', params: { id: paramsUrlId }})
                return
            }
            this.$refs.AbonentInfoModal.paramsUrlId = paramsUrlId
            this.$refs.AbonentInfoModal.dialog = true
        },
        deleteTab(tab){
            this.$store.commit('tabs_abonents/deleteTabAbonent', tab)
        }
    }
}
</script>
