import store from '@/store'

/** Если пользователь авторизирован - перенаправляем на главную страницу */

export default (to, from, next) => {
  //console.log("middleware-guest")
  if (store.getters['auth/check']) {
    next({ name: 'abonents' })
  } else {
    next()
  }
}
