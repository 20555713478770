
const middlewareAbonent = ['auth', 'check-filters', 'check-job-types','check-users', 'check-call-centre-users']

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "user" */ '../views/User/Login.vue'), 
    meta: {layout: '', middleware: 'guest'} 
  },
  {
    path: '/user/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "user" */ '../views/User/Register.vue'),
    meta: {layout: '', middleware: 'guest'} 
  },
  {
    path: '/user/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "user" */ '../views/User/UserSettingsPage.vue'),
    meta: {layout: 'auth', middleware: 'auth'} 
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "user" */ '../views/User/Logout.vue'),
    meta: {layout: '', } //middleware: ['delete-users', 'delete-filters', 'delete-job-types'] 
  },
  {
    path: '/ab',
    name: 'abonent-info-single-page',
    component: () => import('../views/AbonentInfoSinglePage.vue'),
    meta: {layout: 'auth', middleware: ['auth']},
    children: [
      { 
        path: ':id', 
        name: 'abonent_info_single',
        component: () => import('../views/AbonentInfo.vue'),
        meta: {layout: 'auth', middleware: ['auth', 'check-filters', 'check-job-types','check-users', 'check-call-centre-users'] } 
      },
    ] 
  },
  {
    path: '/abonents',
    name: 'abonents',
    component: () => import(/* webpackChunkName: "abonents" */ '../views/Abonents.vue'),
    meta: {layout: 'auth', middleware: ['auth','check-filters', 'check-job-service']},
    children: [
      { 
        path: 'abonent_info/:id', 
        name: 'abonent_info',
        component: () => import(/* webpackChunkName: "abonents" */ '../views/AbonentInfo.vue'),
        meta: {layout: 'auth', middleware: ['auth', 'check-filters', 'check-job-types','check-users', 'check-call-centre-users'] } 
      },
    ] 
  },
  {
    path: '/debtors',
    name: 'debtors',
    component: () => import(/* webpackChunkName: "debtors" */ '../views/Debtors.vue'), 
    meta: {layout: 'auth', middleware: ['auth','check-filters', 'check-job-service']},
    children: [
      { 
        path: 'abonent_info/:id', 
        name: 'abonent_info_debtors',
        component: () => import(/* webpackChunkName: "debtors" */ '../views/AbonentInfo.vue'),
        meta: {layout: 'auth', middleware: ['auth', 'check-filters', 'check-job-types','check-users', 'check-call-centre-users'] } 
      },
    ] 
  },
  {
    path: '/houses',
    name: 'houses',
    component: () => import('../views/Houses.vue'),
    meta: {layout: 'auth', middleware: ['auth', 'check-job-types']},
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/Notifications.vue'),
    meta: {layout: 'auth', middleware: middlewareAbonent},
  },
  {
    path: '/calls',
    name: 'calls',
    component: () => import('../views/Calls.vue'),
    meta: {layout: 'auth', middleware: middlewareAbonent},
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('../views/Messages.vue'),
    meta: {layout: 'auth', middleware: []},
  },
  {
    path: '/switch',
    name: 'switch',
    component: () => import('../views/Switch.vue'),
    //meta: {layout: 'auth', middleware: ['auth']},
    meta: {layout: 'auth'},
  },
  {
    path: '/job',
    name: 'job',
    component: () => import(/* webpackChunkName: "job" */ '../views/Job.vue'),
    meta: {layout: 'auth', middleware: middlewareAbonent},
    children: [
      { 
        path: 'job_info/:id', 
        name: 'job_info',
        component: () => import(/* webpackChunkName: "job" */ '../views/JobInfo.vue'), 
        meta: {layout: 'auth', middleware: 'auth'} 
      },
    ]  
  },
  {
    path: '/pays',
    name: 'pays',
    component: () => import(/* webpackChunkName: "pays" */ '../views/Pays.vue'),
    meta: {layout: 'auth', middleware: middlewareAbonent},
  },
  {
    path: '/mitya',
    name: 'mitya',
    component: () => import('../views/MityaTest.vue'),
    meta: {layout: 'auth', middleware: middlewareAbonent},
  },
  {
    path: '/operator_admin',
    name: 'operator_admin',
    component: () => import(/* webpackChunkName: "operator-admin" */ '../views/OperatorAdmin/OperatorAdminPage.vue'),
    meta: {layout: 'auth', middleware: ['auth', 'check-data-from-operator-admin']},
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/Reports/ReportsPage.vue'),
    meta: {layout: 'auth', middleware: ['auth', 'check-data-from-reports', ...middlewareAbonent]},
  },
  {
    path: '/find_ports',
    name: 'find_ports',
    component: () => import(/* webpackChunkName: "find-ports" */ '../views/FindPorts.vue'),
    meta: {layout: 'auth', middleware: middlewareAbonent},
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/AdminPage.vue'),
    meta: {layout: 'auth', middleware: 'auth'},
    children: [
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewUsers.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      },
      {
        path: 'streets',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewStreets.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      },
      {
        path: 'streets2',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewStreets2.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      },
      {
        path: 'rates',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewRates.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      },
      {
        path: 'operators',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewOperators.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      },
      {
        path: 'type_pays',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewTypePays.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      },
      {
        path: 'type_service',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewTypeService.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      },
      {
        path: 'type_job',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewTypeJob.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      },
      {
        path: 'type_status',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ViewTypeStatus.vue'),
        meta: {layout: 'auth', middleware: 'auth'},
      }
    ]
  },
  
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { 
    path: '/', 
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {layout: 'auth', middleware: 'auth'},
  },
  {
    path: '/*',
    name: '404',
    component: () => import('../views/NotFound.vue'),
    meta: {layout: ''} 
  },
  
]

