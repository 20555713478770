import axios from 'axios'

export const state = {
    operators: JSON.parse(sessionStorage.getItem("operators")) || null,
    pays_type: JSON.parse(sessionStorage.getItem("pays_type")) || null,
    streets: JSON.parse(sessionStorage.getItem("streets")) || null,
    rates: JSON.parse(sessionStorage.getItem("rates")) || null,
    operators_groups: JSON.parse(sessionStorage.getItem("operators_groups")) || null,
    districts: JSON.parse(sessionStorage.getItem("districts")) || null,
  }
  
  // getters
  export const getters = {
    operators: state => state.operators, 
    pays_type: state => state.pays_type,
    streets: state => state.streets,
    rates: state => state.rates,
    rates_tv: state => state.rates && state.rates.filter(x => x.service == 1 && x.enable && !x.is_special && x.speed == 0) || [],
    rates_tv_special: state => state.rates && state.rates.filter(x => x.service == 1 && x.enable && x.speed == 0) || [],

    rates_inet: state => state.rates && state.rates.filter(x => x.enable && !x.is_special && (x.service == 2) ) || [],
    rates_inet_special: state => state.rates && state.rates.filter(x => x.enable && (x.service == 2) ) || [],
    
    rates_double: state => state.rates && state.rates.filter(x => x.enable && !x.is_special && (x.service == 1 && x.speed != 0) ) || [],
    rates_double_special: state => state.rates && state.rates.filter(x => x.enable && (x.service == 1 && x.speed != 0) ) || [],
    
    operators_groups: state => state.operators_groups,
    districts: state => state.districts,
    districts_with_none: state => [{id: 9999, name: 'Без дільниць', operator_group: 0}, ...state.districts],

    districtOfOperator: state => {
      return (operator) => {
        console.log(operator)
        return state.districts.filter(x => x.operator_group == operator)
      }
    },
    
    check_operators: state => state.operators !== null,
    check_pays_type: state => state.pays_type !== null,
    check_streets: state => state.streets !== null,
    check_rates: state => state.rates !== null,
    check_operators_groups: state => state.operators_groups !== null,
    check_filters: state => state.operators !== null && state.pays_type !== null && state.rates !== null && state.operators_groups !== null
  }
  
  // mutations
  export const mutations = {
    updateOperators(state, { operators }) {
      state.operators = operators
      sessionStorage.setItem("operators", JSON.stringify(operators))
    },
    updatePaysType(state, { pays_type }) {
      state.pays_type = pays_type
      sessionStorage.setItem("pays_type", JSON.stringify(pays_type))
    },
    updateStreets(state, { streets }) {
      state.streets = streets
      sessionStorage.setItem("streets", JSON.stringify(streets))
    },
    updateRates(state, { rates }) {
      state.rates = rates
      sessionStorage.setItem("rates", JSON.stringify(rates))
    },
    updateOperatorsGroups(state, { operators_groups }) {
      state.operators_groups = operators_groups
      sessionStorage.setItem("operators_groups", JSON.stringify(operators_groups))
    },
    updateDistricts(state, { districts }) {
      state.districts = districts
      sessionStorage.setItem("districts", JSON.stringify(districts))
    },
    deleteFilters(state){
      state.operators = null
      state.pays_type = null
      state.rates = null
      state.operators_groups = null
      state.districts = null

    }
  
  }
  
  // actions
  export const actions = {
    async fetchOperators ({ commit }) {
      try {
        const { data } = await axios.get('/api/user/list_operators/') 
        //console.log(data);
        commit("updateOperators", { operators: data })
      } catch (e) {
        //console.log("FETCH_OPERATORS_FAILURE")
      }
    },
    async fetchPaysType ({ commit }) {
      try {
        const { data } = await axios.get('/api/cabletv/list_type_pays/') 
        //console.log(data);
        commit("updatePaysType", { pays_type: data })
      } catch (e) {
        //console.log("FETCH_PAYS_TYPE_FAILURE") 
      }
    },
    async fetchStreets ({ commit }) {
      try {
        const { data } = await axios.get('/api/cabletv/list_street/') 
        //console.log(data);
        commit("updateStreets", { streets: data.results })
      } catch (e) {
        //console.log("FETCH_STREETS_FAILURE")
      }
    },
    async fetchRates ({ commit }) {
      try {
        const { data } = await axios.get('/api/cabletv/list_tarifs/') 
        //console.log(data);
        commit("updateRates", { rates: data })
      } catch (e) {
        //console.log("FETCH_RATES_FAILURE")
      }
    },
    async fetchOperatorsGroups ({ commit }) {
      try {
        const { data } = await axios.get('/api/user/list_operators_group/') 
        //console.log(data);
        commit("updateOperatorsGroups", { operators_groups: data })
      } catch (e) {
        //console.log("FETCH_OPERATORS_GROUP_FAILURE")
      }
    },
    async fetchDistricts ({ commit }) {
      try {
        const { data } = await axios.get('/api/cabletv/list_district/') 
        //console.log(data);
        commit("updateDistricts", { districts: data })
      } catch (e) {
        //console.log("FETCH_RATES_FAILURE")
      }
    },
    async deleteFilters ({ commit }) {
      commit("deleteFilters")
    },
  }