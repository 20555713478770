import axios from 'axios'
const devUrlBillingAPI =  process.env.NODE_ENV !== 'production' && 'http://10.110.0.39/api_death_day/' || '/api_death_day/'

export const state = {
    vlans: JSON.parse(sessionStorage.getItem("operator-vlans")) || null,
  }
  
  // getters
  export const getters = {
    vlans: state => state.vlans,
  }
  
  // mutations
  export const mutations = {
    updateVlans(state, vlans) {
      state.vlans = vlans
      sessionStorage.setItem("operator-vlans", JSON.stringify(vlans))
    },
  
  }
  
  // actions
  export const actions = {
    async fetchVlans({ commit }){
      try {
        const { data } = await axios({
          method: "get",url: devUrlBillingAPI,
          params: {req_cmd: "getUserStatusConnect", pcode: 110580010000040},  
        })
        //console.log('vlans: ', data.resp_result[0].vlans) 
        commit("updateVlans", data.resp_result[0].vlans)
      } catch (error) {
        //console.log("FETCH_USERS_FAILURE")
      }    
    },
  
  }