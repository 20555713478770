<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="500px"
      >
        
        <v-card>
          <v-card-title>Виберіть вулицю</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list>
                <v-list-item-group v-model="choiceStreet">
                    <v-list-item
                    @click="agree(item)"
                    v-for="(item, i) in list_street"
                    :key="i"
                    >
                      <v-list-item-content>
                          <v-list-item-title v-text="item.street_view"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
<script>
export default{
    data: () => ({
      choiceStreet: null,
      dialog: false,
      resolve: null,
      reject: null,
      list_street: null,
    }),
    methods: {
      open(list_street) {
        this.choiceStreet = null
        this.list_street = list_street
        this.dialog = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree(item) {
        this.resolve(item)
        this.dialog = false
      }
    }

}
</script>