import axios from 'axios'


export const state = {
    streets: JSON.parse(localStorage.getItem("operator-streets")) || null,
  }
  
  // getters
  export const getters = {
    streets: state => state.streets,
  }
  
  // mutations
  export const mutations = {
    updateStreets(state, {streets}) {
      state.streets = streets
      localStorage.setItem("operator-streets", JSON.stringify(streets))
    },
  
  }
  
  // actions
  export const actions = {
    fetchStreets ({ commit }, ) {
      axios.get('/api/cabletv/list_street/?itemsPerPage=-1')
      .then( response => {
        commit("updateStreets", { streets: response.data.results})
      })
      .catch(() => {
        //
      })
    },
  
  }