import store from '@/store'

/** Если данные пользователя не найдены - перенаправляем на страницу авторизации, (сохраняя путь с которого был совершен переход) */

export default async (to, from, next) => {
  //console.log("middleware-auth")

  if (!store.getters['auth/check_user']) {
    next({ name: 'login', query: {next: to.path} })
    //next({ name: 'login' })
  } else {
    next()
  }
}
