

import Vue from 'vue'
import store from '@/store'
import VueSocketIO from 'vue-socket.io'
//import SocketIO from 'socket.io-client'
import { io } from "socket.io-client";

const options = { 
    autoConnect: false,
    path: "/api_calls_s/socket.io/",
    //transports: ["websocket"],
    withCredentials: true,
    /*extraHeaders: {
      'Authorization': `Bearer ${getToken()}` // ignored
    }*/
     
}; //Options object to pass into SocketIO 

const URL_SOCKET_PROD = ''
//const URL_SOCKET_DEV = 'http://10.111.0.222:5000'
//const URL_SOCKET_DEV = 'http://10.110.0.36:5000'
//const URL_SOCKET_DEV = 'http://192.168.5.17:5000'
const URL_SOCKET_DEV = 'http://10.110.0.39:5000'


const socket = io(process.env.NODE_ENV !== 'production' && URL_SOCKET_DEV || URL_SOCKET_PROD, options) //options object is Optional

Vue.use(new VueSocketIO({
    //debug: true,
    connection: socket,
    //vuex: {
      //store,
      //actionPrefix: "SOCKET_",
      //mutationPrefix: "SOCKET_" 
    //}
  })
);

socket.on("connect", (data) => {
  console.log("connect", data)
  store.commit("socket/updateStatus", "connected")
});

socket.on("connect_error", (err) => {
  console.log("connect_error", err.message)
  if (err.message === "invalid credentials") {
    socket.io.opts.extraHeaders = {'Authorization': `Bearer ${store.getters['auth/token_access'] }`}
    //socket.auth.token = "efgh";
    //socket.connect();
  }
  if(err.message == "Connection rejected by server"){
    //store.commit("socket/updateStatus", "disconnected")
  }else{
    store.commit("socket/updateStatus", "reconnecting")
  }

  
});

socket.on("disconnect", (reason) => { 
  console.log("disconnect", reason)
  store.commit("socket/updateStatus", "disconnected")
});



//socket.io.on("open", console.log("open"));
//socket.io.on("close", console.log("close"));
//socket.io.on("error",           (error) => { console.log("error", error.message) });
//socket.io.on("reconnect_error", (error) => { console.log("reconnect_error:", error.message) });
//socket.io.on("reconnect",            () => { console.log("reconnect") });
//socket.io.on("ping",                 () => { console.log("ping") });
