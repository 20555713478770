import Vue from 'vue'
import store from '@/store'
import Meta from 'vue-meta'
import routes from './routes'
import Router from 'vue-router'
import { sync } from 'vuex-router-sync'
import { setTimeout } from 'core-js'

Vue.use(Meta)
Vue.use(Router)

// The middleware for every page of the application.
const globalMiddleware = ['check-auth', 'check-user'] 

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
  require.context('@/middleware', false, /.*\.js$/)
)

const router = createRouter()

sync(store, router)

export default router

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter () {
  const router = new Router({
    hashbang: true,
    mode: 'history',
    routes
  })

  router.beforeEach(beforeEach)
  router.afterEach(afterEach)

  return router
}

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach (to, from, next) {
  
  router.app.$nextTick(() => router.app.$loading.start())

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(to.meta.middleware)
  // Call each middleware.
  callMiddleware(middleware, to, from, (...args) => {
    // Set the application layout only if "next()" was called with no args.
    if (args.length === 0) {
      router.app.$nextTick(() => router.app.setLayout(to.meta.layout || undefined))
    }
    next(...args)
  })
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 */
async function afterEach () {
  
  await router.app.$nextTick(() => router.app.$loading.finish())
  document.getElementById('doc-loader').style.display = 'none'
  //router.app.$vuetify.goTo(1, {duration: 100})
  //router.app.$vuetify.mobile && window.scrollTo(0, 0)
  setTimeout(() => {window.scrollTo(0, 0)}, 140)
  
}

/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware (middleware, to, from, next) {
  const stack = middleware.reverse()

  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      
      if (args.length > 0) {
        //router.app.$nextTick(() => router.app.$loading.finish())
      }

      return next(...args)
    }

    const middleware = stack.pop()

    if (typeof middleware === 'function') {
      middleware(to, from, _next)
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next)
    } else {
      throw Error(`Прослойка не найдена [${middleware}]`)
    }
  }

  _next()
}

/**
 * Merge the the global middleware with the components middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware (to_middleware) {
  const middleware = [...globalMiddleware]
  if (!to_middleware) {
    return middleware
  } 
    if (Array.isArray(to_middleware)) {
      middleware.push(...to_middleware)
    } else {
      middleware.push(to_middleware)
    }
  return middleware
}


/**
 * @param  {Object | Function} requireContext
 * @return {Object}
 */
function resolveMiddleware (requireContext) {
  return requireContext.keys()
    .map(file =>
      [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((guards, [name, guard]) => (
      { ...guards, [name]: guard.default }
    ), {})
}
