import store from '@/store'

/** Если данные типов заявок не найдены  - пытаемся запросить данные на сервере */

export default async (to, from, next) => {
  if (!store.getters['job_types/check_service']) { 
    try {
      await store.dispatch('job_types/fetchService')
    } catch (e) {
      //
    }
  }

  next()
}
