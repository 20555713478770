<template>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color=color
    >
      <v-icon class="mr-4">{{ icon }}</v-icon> {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  
</template>


<script>
  export default {
    data: () => ({
      iconDefault: 'mdi-checkbox-marked-circle',
      icon: null,
      snackbar: false,
      text: '',
      timeout: 3000,
      color: ''
    }),
    methods: {
        show(message, color){
            this.snackbar = true;
            this.text = message;
            this.color = color || this.$vuetify.theme.dark && 'primary darken-1' || 'primary'
            this.icon = color && 'mdi-alert-circle' || this.iconDefault
            this.timeout = color && -1 || 3000
        },
    }
  }
</script>