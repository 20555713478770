<template>
    <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-center align-centr" >
            <div class="pt-8 pb-5"><h2 class="text--red">{{ text }}</h2></div>  
            <div class="pb-12"><h3>{{ error }}</h3></div>  
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
export default {
    name: "sheet",
    data: () => ({
        sheet: false,
        text: "",
        error: [],
    }),
    methods: {
        show(message, error){
            this.sheet = true;
            this.text = message;
            this.error = error && this.parsErrors(error) || '';
            /*setTimeout(() => (
                this.sheet = false,
                this.message = "",
                this.error = ''
            ), 5000);*/
        },
        parsErrors(error){
            if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

                let temp_err = [];
                temp_err = error.response.data[0] && error.response.data || [];
                if (!temp_err.length && error.response.data) {
                    Object.keys(error.response.data).forEach(element => {
                        if(typeof(error.response.data[element]) == 'string'){
                            temp_err.push(`${element}: ${error.response.data[element]}`)  
                        }else if(typeof(error.response.data[element]) == 'object'){
                            temp_err.push(`${element}: ${error.response.data[element][0]}`)  
                        }
                        
                    });
                    return temp_err.join(" | ")
                }else{
                    if(typeof(temp_err) == "string" && temp_err[0] != '<') return temp_err
                    else if(typeof(temp_err) == "string") return temp_err
                    return temp_err[0]
                }
            //console.log(error.response.status);
            //console.log(error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
            } else {
                return error
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
            }
            //console.log(error.config);
        }
    }
}
</script>

