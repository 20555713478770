<template>
<div v-scroll="onScroll">
  <v-btn
    v-if="offsetTop > 50 && !$router.app.printMode"
    
    color="primary"
    class="btn-scroll"
    fixed
    bottom
    left
    small
    dark
    fab
    @click="$vuetify.goTo(1, {duration: 300})"
  >
    <v-icon dark>
      mdi-chevron-up
    </v-icon>
  </v-btn>
</div>
    
</template>
<script>
export default {
  data: () => ({
    offsetTop: 0,
  }),
  methods:{
    onScroll (e) {
      //console.log(e.target?.scrollingElement?.scrollTop)
      //console.log(e.path[1].scrollY)
      this.offsetTop = e.target?.scrollingElement?.scrollTop || 0
    },
  }
}
</script>
<style scoped>
  .btn-scroll {
    bottom: 10px !important;
  }
</style>