export const state = {
    status: null,
  }
  
  // getters
  export const getters = {
    status: state => state.status,
  }
  
  // mutations
  export const mutations = {
    updateStatus(state, newStatus) {
      state.status = newStatus
    },
  
  }