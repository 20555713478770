

export const state = {
    rates: JSON.parse(localStorage.getItem("operator-rates")) || null,
  }
  
  // getters
  export const getters = {
    rates: state => state.rates,
  }
  
  // mutations
  export const mutations = {
    updateRates(state, {rates}) {
      state.rates = rates
      localStorage.setItem("operator-rates", JSON.stringify(rates))
    },
  
  }
  
  // actions
  export const actions = {
    saveRates ({ commit }, rates) {
      commit("updateRates", rates)
    },
  
  }