<template>
        <v-system-bar
            v-if="showBar"
            height="30"
            :color="type == 'call' ? 'green' : 'primary'"
            app
            >
            <v-icon class="ml-2" color="white">mdi-phone-in-talk</v-icon>
            <v-spacer></v-spacer>
            <template v-if="usersData">
                <v-btn outlined
                color="white"
                class="mr-7" 
                v-if="usersData.length == 1" x-small :to="{ name: 'abonent_info', params: { id: usersData[0].id }}">Перейти</v-btn>
                <template v-else-if="usersData.length > 1">
                    <v-menu open-on-hover buttom offset-y>
                    <template v-slot:activator="{ on }">
				<v-btn 
                outlined
                color="white"
                class="mr-7"
					v-on="on" x-small
				>
					Перейти
				</v-btn>
                </template>
                <v-list dense>
                    <v-list-item three-line v-for="(user, index) of usersData" :key="index" :to="{ name: 'abonent_info', params: { id: user.id }}">
                        <v-list-item-content>
                            <v-list-item-title>{{ user.address }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-if="user.operator">({{ user.operator }})</span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>{{ user.full_name }} </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    
                </v-list>
                </v-menu> 
                </template>
            </template>
            
            <span class="white--text copy-phone-span text-ex-space" title="Копіювати номер" :class="clickCopyPhone == nowPhone ? 'primary--text text-lighten-2' : ''" @click.stop="$router.app.copyText(nowPhone), clickCopyPhone = nowPhone" @mouseleave="clickCopyPhone = false"><b>{{ nowPhone }}</b>
                <v-icon v-if="clickCopyPhone != nowPhone" small  class="copy-phone-icon-md ml-1" color="white">mdi-content-copy</v-icon>
                <v-icon v-else small  class="copy-phone-icon-md ml-1" color="primary">mdi-content-save-check</v-icon>
            </span>

            <span v-if="type == 'call'" class="white--text ml-2"><b>{{ this.minutes }}:{{ this.text_seconds }}</b></span>
            <span v-else class="white--text ml-2">Зʼєднання...</span> 
            <v-spacer />
            <v-btn title="Закрити" x-small icon @click="closeBar"><v-icon color="white" class="pl-1">mdi-close</v-icon></v-btn>
        </v-system-bar>
    
</template>
<script>
import axios from "axios"
export default {
    data: () => ({
        clickCopyPhone: false,
        showBar: false,
        timer: null,
		hours: 0,
        seconds: 0,
        minutes: 0,
        text_seconds: '00',
        nowPhone: '',
        usersData: {},
        type: 'call'

	}),
    mounted(){
        //setTimeout(() => { this.show('+380676908955') },3000)
        
    },
    methods:{
        show(phone, type){
            this.type = type
            this.showBar = !this.showBar
            this.nowPhone = phone
            if(this.showBar && this.type == 'call'){
                this.clearTimer()
                this.timer = setInterval(this.displayTimer,1000)
            }
            this.getUserForPhone(phone)
            
        },
        clearTimer(){
            if (this.timer) clearInterval(this.timer);
            [this.seconds,this.minutes,this.hours,this.text_seconds] = [0,0,0,'00'];
        },
        displayTimer(){
            this.seconds+=1;
            
            if(this.seconds == 60){
                this.seconds = 0;
                this.minutes++;
                if(this.minutes == 60){
                    this.minutes = 0;
                    this.hours++;
                }
            }
            if(this.seconds < 10 ) this.text_seconds = '0' + String(this.seconds)
            else this.text_seconds = this.seconds
        },
        closeBar(){
            this.clearTimer()
            this.showBar = false
            this.nowPhone = ''
            this.usersData = {}
        },
        getUserForPhone(phone){
            axios.get(`/api/cabletv/find_phones/?phone=${phone}`)
            .then((req) => {
                this.usersData = req.data.results
            })
            
		},
    }
}
</script>
<style scoped>
.text-ex-space{
  white-space: nowrap !important /* Запрещаем перенос слов */
}
.copy-phone-icon-md{
  visibility: hidden;
}
.copy-phone-span{
    cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}
.copy-phone-span:hover > .copy-phone-icon-md{
  visibility: visible;
}
</style>