import store from '@/store'

/** Якщо вийшов срок токену але знайдено токен оновлення - спробуємо оновити токен */

export default async (to, from, next) => {
  //console.log("middleware-check-auth")
  if (!store.getters['auth/isTokenAccessActive'] && store.getters['auth/token_refresh']) {
    try {
      await store.dispatch('auth/fetchRefreshToken')  
    } catch (e) {
      next({ name: 'logout' })
    }
  }

  next()
}
